<template>
  <div class="page-404">
    <div class="mainer">
      <img class="pic" src="@/assets/images/common/404.png">
      <div class="tip1">抱歉，页面出错了</div>
      <div class="tip2">可能的原因：网络不稳定，尚未接入互联网，页面不存在…</div>
      <div class="tip2">请检查网络是否正确，或者重新加载页面</div>
      <div class="btn-c">
        <el-button type="primary" @click="reload">重新加载</el-button>
        <el-button @click="goHome">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    reload() {
      location.reload();
    },
    goHome() {
      this.$router.push({
        path: '/',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-404 {
  height: 100%;
  background-color: #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  overflow: auto;
  .mainer {
    width: 400px;
    text-align: center;
    .pic {
      width: 196px;
      height: 196px;
      display: inline-block;
      margin-bottom: 32px;
    }
    .tip1 {
      height: 32px;
      font-size: 24px;
      color: #4F5769;
      line-height: 32px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .tip2 {
      height: 19px;
      font-size: 14px;
      color: #969BA5;
      line-height: 19px;
      margin-bottom: 4px;
    }
    .btn-c {
      padding-top: 24px;
      text-align: center;
    }
  }
}
</style>
